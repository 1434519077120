export const GetGiftCardsQuery = `
  query GetGiftCards($page: Int!, $limit: Int!, $filters: GiftCardFiltersInput) {
    giftCards(limit: $limit, filters: $filters) {
      totalNumberOfPages
      page (page: $page) {
        id  
        code
        createdAt
        expiresAt
        amount {
          amount
          currency
        }
      }
    }
  }
`

export const GetGiftCardQuery = `
  query GetGiftCard($id: GiftCardId!) {
    giftCard (id: $id) {
      id
      code
      createdAt
      expiresAt
      amount {
        amount
        currency
      }
      usages {
        order {
          id
          reference
        }
        customer {
          id
          firstName
          lastName
        }
        usedAt
        initialAmount {
          amount
          currency
        }
        remainingAmount {
          amount
          currency
        }
      }
    }
  }
`
