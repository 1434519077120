import App from './App.vue'
import router from "./router"
import store from "./store"
import Vue from 'vue'
import VueRouter from "vue-router"
import vuetify from './plugins/vuetify'
import Vuex from "vuex"

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Vuex)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
