<template>
  <Page :loading="loading" :error="error" >
    <template v-slot:form-modals><AddOrEditModal :edit="true"/></template>
    <template v-if="coupon" v-slot:title>
        <v-card outlined elevation="2">
            <v-card-title class="grey lighten-4">
                <h2 v-if="!coupon.isAuto">Détails du code promo {{ coupon.code }}</h2>
                <h2 v-else>Détails de l'auto discount {{ coupon.code }}</h2>
                <v-spacer></v-spacer>
                <v-icon v-if="coupon.deactivated" color="red darken-4" class="mr-2">
                    mdi-lock
                </v-icon>
                <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="openDeactivationModal" v-if="!coupon.deactivated">
                            <v-list-item-icon>
                              <v-icon>mdi-lock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Désactiver
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openEditModal">
                          <v-list-item-icon>
                            <v-icon>mdi-table-edit</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            Editer
                          </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-title>
        </v-card>
    </template>
    <template><DeactivationModal /></template>
    <template v-if="coupon">
      <v-row>
        <v-col cols="12" md="4" >
          <v-card height="100%">
            <v-card-title>
              Général
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="font-weight-medium">Code</v-col>
                <v-col class="font-weight-light">
                  {{ coupon.code }}
                  <span v-if="coupon.isAuto" class="font-weight-bold">(AUTO)</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">Créé le</v-col>
                <v-col class="font-weight-light">{{ coupon.createdAt }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">Nombre d'usages</v-col>
                <v-col class="font-weight-light">
                  {{ coupon.totalUsages }} / <v-icon small color="grey">mdi-infinity</v-icon>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">Effet</v-col>
                <v-col class="font-weight-light">
                  {{ coupon.effectDetails }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card height="100%">
            <v-card-title>
              Conditions
            </v-card-title>
            <v-card-text>
              <v-row v-if="coupon.condition.customer">
                <v-col class="font-weight-medium">Client</v-col>
                <v-col class="font-weight-light">
                  <router-link :to="{ name: 'customerDetails', params: { id: coupon.condition.customer.id } }">
                    {{ coupon.condition.customer.fullName }}
                  </router-link>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium" v-if="coupon.condition.expiresAt">Date d'expiration</v-col>
                <v-col class="font-weight-light">{{ coupon.condition.expiresAt }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">Nombre de commandes requises</v-col>
                <v-col class="font-weight-light">

                  <template v-if="coupon.condition.numberOfOrders === 0">
                    Première commande
                  </template>
                  <template v-else-if="null !== coupon.condition.numberOfOrders">
                    {{ coupon.condition.numberOfOrders }}
                  </template>
                  <template v-else>
                    -
                  </template>

                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">Nombre de jour depuis l'inscription à la newsletter</v-col>
                <v-col class="font-weight-light">{{ coupon.condition.newsletterSubscriptionDays }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">Montant minimum</v-col>
                <v-col class="font-weight-light">{{ coupon.condition.minimalAmount }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">Appli uniquement</v-col>
                <v-col class="font-weight-light">{{ coupon.condition.appOnly }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col data-testid="info-tab" cols="12" md="10">
          <v-card>
            <v-card-text>
              <v-tabs v-model="tab">
                <v-tab key="usages">Utilisations</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab" class="my-4">
                <v-tab-item key="usages">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Date</th>
                        <th>Commande</th>
                        <th>Client</th>
                        <th>Pays</th>
                        <th class="text-right">Montant initial</th>
                        <th class="text-right">Montant restant</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="(usage, i) in coupon.usages"
                          :key="i"
                      >
                        <td>{{ usage.usedAt }}</td>
                        <td>
                          <router-link :to="{ name: 'orderDetails', params: { id: usage.order.id } }">
                            #{{ usage.order.reference }}
                          </router-link>
                        </td>
                        <td>
                          <router-link :to="{ name: 'customerDetails', params: { id: usage.customer.id } }">
                            {{ usage.customer.fullName }}
                          </router-link>
                        </td>
                        <td>{{ getCountryFlag(usage.order.deliveryAddress.countryCode )}}</td>
                        <td class="text-right">{{ usage.initialAmount }}</td>
                        <td class="text-right">{{ usage.remainingAmount }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div class="text-center  mt-8" v-if="nbPages">
                    <v-pagination
                        v-model="page"
                        :length="nbPages"
                        :total-visible="8"
                        rounded="circle"
                    ></v-pagination>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </Page>
</template>

<script>
import { alpha3toAlpha2CountryCode, countryCodeEmoji } from '../../helpers/countries'
import { CouponAction, CouponGetter, CouponMutation } from "../../store/types"
import { formatDatetime, formatDuration, shortUuid } from "../../utils"
import AddOrEditModal from './AddOrEditModal.vue'
import DeactivationModal from "./DeactivationModal"
import { mapGetters } from 'vuex'
import Page from "../Layout/Page"

export default {
  components: {
    AddOrEditModal,
    Page,
    DeactivationModal,
  },

  data() {
    return {
      tab: null,
    }
  },

  mounted() {
    this.$store.dispatch(CouponAction.GetOne, { id: this.$route.params.id })
  },

  computed: {
    ...mapGetters({
      loading: CouponGetter.IsLoading,
      error: CouponGetter.HasError,
      coupon: CouponGetter.Details,
      nbPages: CouponGetter.TotalUsagePages,
    }),
    page: {
      get() { return this.$store.getters[CouponGetter.UsagePage] },
      set(page) { this.$store.dispatch(CouponAction.GetOne, {
        id: this.$route.params.id,
        page,
      }) },
    },
  },
  methods: {
    formatDatetime,
    formatDuration,
    shortUuid,
    openDeactivationModal() {
      this.$store.commit(CouponMutation.OpenDeactivationModal)
    },
    getCountryFlag: (countryCode) => countryCodeEmoji(alpha3toAlpha2CountryCode(countryCode)),
    openEditModal() {
      this.$store.commit(CouponMutation.OpenEditModal)
    },
  },
}
</script>
