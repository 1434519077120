<template>
  <div>
    <ConfirmDialog
        v-model="showRefundConfirmModal"
        @confirm="refund(); showRefundConfirmModal = false"
        @cancel="showRefundConfirmModal = false"
    >
      <template #text>
        Vous allez effectuer un remboursement de type "{{ refundReasonText }}"
        et d'un montant de {{ amountToRefund }}. Etes-vous sûr ?
      </template>
    </ConfirmDialog>
    <div class="d-flex justify-end pb-6">
      <v-btn
          v-if="order.isRefundable"
          :disabled="refundMode"
           link  target="_blank" color="orange lighten-3"
           title="Afficher la facture"
           @click="refundMode = true"
      >
        <v-icon>mdi-cash-refund</v-icon> Rembourser
      </v-btn>
    </div>
    <v-alert v-if="hasRefundErrors" type="error" max-width="500px">
      Une erreur inattendue s'est produite.
    </v-alert>
    <v-card
        outlined
        shaped elevation="2"
        class="mb-4"
    >
      <v-card-title class="grey lighten-4">
        Livraison
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <ItemLine
          :image="order.shippingMethod.deliveryCarrierIconSrc"
          :initialAmount="order.priceDetails.shipping"
          :reductionAmount="order.priceDetails.shippingReduction"
          :paidAmount="order.shippingPaidAmount"
          :refundedAmount="order.shippingRefundedAmount"
          :refundableAmountValue="order.shippingRefundableAmountValue"
          :refund-mode="refundMode"
          @refundAmountChanged="shippingRefundAmountChanged($event)"
        >
          <template #name>
            <b>{{ order.shippingMethod.deliveryCarrier }}</b>
          </template>
        </ItemLine>
        <ItemLine v-if="order.priceDetails.qualityControl"
            :initialAmount="order.priceDetails.qualityControl"
            :reductionAmount="order.priceDetails.qualityControlReduction"
            :paidAmount="order.qualityControlPaidAmount"
            :refundedAmount="order.qualityControlRefundedAmount"
            :refundableAmountValue="order.qualityControlRefundableAmountValue"
            :refund-mode="refundMode"
            @refundAmountChanged="qualityControlRefundAmountChanged($event)"
        >
          <template #name>
            <b>Contrôle qualité</b>
          </template>
        </ItemLine>
      </v-card-text>
    </v-card>

    <v-card
        v-for="(orderItemsByStore, s) in order.orderItemsByStores"
        :key="s"
        outlined
        shaped elevation="2"
        class="mb-4"
    >
      <v-card-title class="grey lighten-4">
        {{ orderItemsByStore.store.name }} {{ getStoreCountryFlag(orderItemsByStore.store.country) }}
      </v-card-title>
      <v-card-text>
        <ItemLine
            v-for="(item, i) in orderItemsByStore.orderItems"
            :key="i"
            :image="item.product.image"
            :extra="item.product.size"
            :initialAmount="item.amount"
            :reductionAmount="item.reductionAmount"
            :paidAmount="item.paidAmount"
            :refundedAmount="item.refundedAmount"
            :refundableAmountValue="item.refundableAmountValue"
            :refund-mode="refundMode"
            :pre-fill-refund="preFillRefundOrderItemIds.includes(item.id)"
            @refundAmountChanged="productRefundAmountChanged($event, item.product.reference, item.product.variantId)"
        >
          <template #name>
            <b>
              <a :href="getProductUrl(item.product)" target="_blank">{{ item.product.name }}</a>
            </b>
            <template v-if="item.product.sku">
              <br/>{{ item.product.sku }}
            </template>
            <br />
            <span
                v-if="item.returnEligibility.eligible"
                class="green--text text--darken-2"
            >
              Eligible au retour jusqu'au {{ item.returnEligibility.dateLimit }}
            </span>
            <span v-else class="red--text text--darken-2">
              Non éligible au retour
              <v-tooltip bottom color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      class="mb-1 red--text text--darken-2"
                  >
                    <v-icon aria-hidden="false">
                      mdi-help-circle-outline
                    </v-icon>
                  </v-btn>
                </template>
                <div class="black--text">
                  Raison: {{ getIneligibilityReason(
                    item.returnEligibility.ineligibilityReason,
                    item.returnEligibility.dateLimit
                ) }}
                </div>
              </v-tooltip>
            </span>
          </template>
        </ItemLine>
      </v-card-text>
    </v-card>
    <div v-if="refundMode" class="d-flex justify-end pt-6">
      <div style="min-width: 500px;">
        <v-select
            :items="refundReasons"
            item-value="name"
            filled
            label="Veuillez saisir le motif de retour"
            dense
            v-model="reason"
            :error-messages="errors.reason"
        ></v-select>

        <v-simple-table>
          <template v-slot:default>
            <tbody>
            <tr>
              <td><b>Montant total payé par la cliente</b></td>
              <td class="text-right">{{ order.paidAmount }}</td>
            </tr>
            <tr v-if="order.refundedAmount">
              <td><b>Montant déjà remboursé</b></td>
              <td class="text-right">{{ order.refundedAmount }}</td>
            </tr>
            <tr>
              <td><b>Montant du remboursement</b></td>
              <td class="text-right">{{ amountToRefund }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="d-flex justify-space-around pt-6">
          <v-btn
              link  target="_blank"
              title="Annuler"
              @click="cancelRefund"
          >
            <v-icon>mdi-cancel</v-icon> Annuler
          </v-btn>
          <v-btn
              link  target="_blank"
              title="Rembourse"
              @click="showRefundConfirmModal = true"
          >
            <v-icon>mdi-cash-refund</v-icon> Rembourser
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { alpha3toAlpha2CountryCode, countryCodeEmoji } from "../../../helpers/countries"
import { OrderAction, OrderGetter } from "../../../store/types"
import ConfirmDialog  from "./../../Core/ConfirmDialog"
import { formatPrice } from "../../../utils"
import ItemLine  from "./ItemLine"
import { REFUND_REASONS }  from  "../../../helpers/order"

export default {
  components: {
    ConfirmDialog,
    ItemLine,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    let reasons = []
    for (const reason in REFUND_REASONS) {
      reasons.push({
        name: reason,
        text: REFUND_REASONS[reason],
      })
    }

    return {
      refundReasons: [
        { name: null, text: '' },
        ...reasons,
      ],
      refundMode: false,
      shippingRefundAmount: 0.,
      qualityControlRefundAmount: 0.,
      productRefundAmounts: [],
      currency: 'EUR',
      reason: null,
      note: '',
      errors: {},
      showRefundConfirmModal: false,
      preFillRefundOrderItemIds: [],
    }
  },
  mounted () {
    const urlParams = new URLSearchParams(window.location.search)
    this.preFillRefundOrderItemIds = urlParams.getAll('orderItemToRefund') || []
    if (this.preFillRefundOrderItemIds.length) {
      this.refundMode = true
    }

    const reason = urlParams.get('reason')
    if (REFUND_REASONS[reason]) {
      this.reason = reason
    }
  },
  watch: {
    refundMode(newValue) {
      if (!newValue) {
        this.shippingRefundAmount = 0.
        this.qualityControlRefundAmount = 0.
        this.productRefundAmounts = []
      }
    },
    reason() {
      this.errors = Object.assign({}, this.errors, { reason: null })
    },
  },
  computed: {
    hasRefundErrors() {
      return this.$store.getters[OrderGetter.RefundErrors].internal

    },
    amountToRefund() {
      if (!this.currency) {
        return ''
      }
      const amount =  this.productRefundAmounts.reduce(
        (acc, productRefundAmount) => acc + productRefundAmount.amount,
        (this.shippingRefundAmount || 0) + (this.qualityControlRefundAmount || 0)
      )

      return formatPrice({
        amount: amount,
        currency: this.currency,
      })
    },
    refundReasonText() {
      return REFUND_REASONS[this.reason] ?? ''
    },
  },
  methods: {
    getStoreCountryFlag(countryCode) {
      return countryCodeEmoji(alpha3toAlpha2CountryCode(countryCode))
    },
    cancelRefund() {
      this.refundMode = false
    },
    shippingRefundAmountChanged(value) {
      this.shippingRefundAmount = Math.round(100 * parseFloat(value))
    },
    qualityControlRefundAmountChanged(value) {
      this.qualityControlRefundAmount = Math.round(100 * parseFloat(value))
    },
    productRefundAmountChanged(value, productReference, productVariantId) {
      const amount = Math.round(100 * parseFloat(value))
      const productRefundAmounts = [...this.productRefundAmounts]
      let update = false
      for (let i=0; i<productRefundAmounts.length; i++) {
        if (
          productRefundAmounts[i]['productReference'] === productReference &&
          productRefundAmounts[i]['productVariantId'] === productVariantId
        ) {
          this.productRefundAmounts[i]['amount'] = amount
          update = true
          break
        }
      }
      if (!update) {
        productRefundAmounts.push({
          productReference,
          productVariantId,
          amount: Math.round(100 * parseFloat(value)),
        })
      }
      this.productRefundAmounts = productRefundAmounts
    },

    refund() {
      if (!this.reason) {
        this.errors = Object.assign({}, this.errors, { reason: 'Veuillez sélectionner un motif de remboursement' })

        return
      }
      this.$store.dispatch(OrderAction.Refund, {
        shippingRefundAmount: {
          amount: this.shippingRefundAmount,
          currency: this.currency,
        },
        qualityControlRefundAmount: {
          amount: this.qualityControlRefundAmount,
          currency: this.currency,
        },
        productRefundAmounts: this.productRefundAmounts.map(productRefundAmount => {
          return {
            productReference: productRefundAmount.productReference,
            productVariantId: productRefundAmount.productVariantId,
            amount: {
              amount: productRefundAmount.amount,
              currency: this.currency,
            },
          }
        }),
        reason: this.reason,
        note: this.note,
      }).then(() => this.cancelRefund())
    },

    getProductUrl(product) {
      return process.env.VUE_APP_MARKETPLACE_BASE_URL + '/produit/' + product.slug + '-' + product.reference
    },
    getIneligibilityReason(reason, dateLimit) {
      switch (reason) {
      case 'not_shipped':
        return 'non livré'
      case 'date_limit_exceeded':
        return 'date de fin de retour:' + dateLimit
      case 'pending_return':
        return 'article retourné'
      case 'other':
        return 'autre'
      default:
        return 'inconnu'
      }
    },
  },
}
</script>
