import {
  CouponAction,
  CouponGetter,
  CouponMutation,
} from "../types"
import { effects as couponEffects, formatEffect } from "../../helpers/coupon"
import {
  CreateCouponAmountMutation,
  CreateCouponFreeShippingMutation,
  CreateCouponPercentMutation,
  DeactivateCouponMutation,
  GetCouponQuery,
  GetCouponsQuery,
  UpdateCouponAmountMutation,
  UpdateCouponFreeShippingMutation,
  UpdateCouponPercentMutation,
} from "../../graphql/coupon"
import {
  formatDate,
  formatDatetime,
  formatFullName,
  formatPrice,
  handleGraphQLErrors,
} from "../../utils"
import http from "../../http"

const Status = {
  Default: "default",
  Loading: "loading",
  Failed: "failed",
  Succeed: "succeed",
}

const resultsPerPage = 15

export default {
  state: {
    coupons: [],
    coupon: null,
    status: Status.Default,
    creationErrors: {},
    page: 1,
    totalPages: null,
    filters: {
      isAuto: false,
    },
    errors: {},
    creationStatus: null,
    showAddModal: false,
    showEditModal: false,
    usagePage: 1,
    totalUsagePages: null,
    showDeactivationModal: false,
  },

  mutations: {
    [CouponMutation.GetAllSucceed]: (state, payload) => {
      state.coupons = payload
      state.status = Status.Succeed
    },
    [CouponMutation.GetOneSucceed]: (state, payload) => {
      state.coupon = payload
      state.status = Status.Succeed
    },
    [CouponMutation.CreationSucceed]: (state) => state.status = Status.Succeed,
    [CouponMutation.CreationErrors]: (state, error) => {
      state.creationErrors = error
      state.status = Status.Default
    },
    [CouponMutation.Failed]: (state) => state.status = Status.Failed,
    [CouponMutation.Loading]: (state) => state.status = Status.Loading,
    [CouponMutation.Page]: (state, payload) => state.page = payload,
    [CouponMutation.TotalPages]: (state, payload) => state.totalPages = payload,
    [CouponMutation.ApplyFilters]: (state, payload) => state.filters = payload,
    [CouponMutation.OpenAddModal]: (state) => {
      state.creationErrors = {}
      state.showAddModal = true
    },
    [CouponMutation.OpenEditModal]: (state) => {
      state.creationErrors = {}
      state.showEditModal = true
    },
    [CouponMutation.CloseModal]: (state) => {
      state.showAddModal = false
      state.showEditModal = false
    },
    [CouponMutation.UsagePage]: (state, payload) => state.usagePage = payload,
    [CouponMutation.TotalUsagePages]: (state, payload) => state.totalUsagePages = payload,
    [CouponMutation.OpenDeactivationModal]: (state) => state.showDeactivationModal = true,
    [CouponMutation.CloseDeactivationModal]: (state) => state.showDeactivationModal = false,
    [CouponMutation.Errors]: (state, error) => {
      state.errors = error
    },
  },

  actions: {
    [CouponAction.GetAll]: async ({ state, commit }, page) => {
      commit(CouponMutation.Loading)

      page = page || state.page || 1
      http.post('/graphql', JSON.stringify({
        query: GetCouponsQuery,
        page: page,
        variables: {
          page: page,
          limit: resultsPerPage,
          filters: state.filters,
        },
      }))
        .then((res) => {
          commit(CouponMutation.Page, page)
          commit(CouponMutation.GetAllSucceed, res.data.data.coupons.page)
          commit(CouponMutation.TotalPages, res.data.data.coupons.totalNumberOfPages)
        })
        .catch(() => commit(CouponMutation.Failed))
    },
    [CouponAction.GetOne]: async ({ state, commit }, { id, page }) => {
      commit(CouponMutation.Loading)

      page = page || state.usagePage || 1

      http.post('/graphql', JSON.stringify({
        query: GetCouponQuery,
        variables: { id, pageCouponUsages: page },
      }))
        .then((res) => {
          commit(CouponMutation.UsagePage, page)
          commit(CouponMutation.GetOneSucceed, res.data.data.coupon)
          commit(CouponMutation.TotalUsagePages, res.data.data.coupon.usages.totalNumberOfPages)
        })
        .catch(() => {

          commit(CouponMutation.Failed)
        })
    },
    [CouponAction.Create]: async ({ commit, dispatch }, payload) => {
      commit(CouponMutation.Loading)

      let mutation

      switch (payload.effect) {
      case couponEffects.amount:
        mutation = CreateCouponAmountMutation
        break
      case couponEffects.percent:
        mutation = CreateCouponPercentMutation
        break
      case couponEffects.freeShipping:
        mutation = CreateCouponFreeShippingMutation
        break
      default:
        commit(CouponMutation.Failed)

        return
      }

      http.post('/graphql',  JSON.stringify({
        query: mutation,
        variables: { input: payload.coupon },
      }))
        .then((res) => {
          if (res?.data?.errors?.[0]?.state?.input) {
            const errors = {}
            res.data.errors[0].state.input.forEach((item) => {
              if (errors[item.path]) {
                errors[item.path] += "/" + item.message
              } else {
                errors[item.path] = item.message
              }
            })
            commit(CouponMutation.CreationErrors, errors)

            return
          } else if (res?.data?.errors?.[0]?.message) {
            commit(CouponMutation.CreationErrors, { global: res?.data?.errors?.[0]?.message } )

            return
          }

          commit(CouponMutation.CreationSucceed)
          commit(CouponMutation.CloseModal)
          dispatch(CouponAction.GetAll)
        })
        .catch(() => commit(CouponMutation.CreationErrors, { global: 'Internal error' } ))
    },

    [CouponAction.Update]: async ({ commit, dispatch }, payload) => {
      commit(CouponMutation.Loading)

      let mutation

      switch (payload.effect) {
      case couponEffects.amount:
        mutation = UpdateCouponAmountMutation
        break
      case couponEffects.percent:
        mutation = UpdateCouponPercentMutation
        break
      case couponEffects.freeShipping:
        mutation = UpdateCouponFreeShippingMutation
        break
      default:
        commit(CouponMutation.Failed)

        return
      }

      const id = payload.coupon.id
      delete payload.coupon.id

      http.post('/graphql',  JSON.stringify({
        query: mutation,
        variables: { input: payload.coupon },
      }))
        .then((res) => {
          if (res?.data?.errors?.[0]?.state?.input) {
            const errors = {}
            res.data.errors[0].state.input.forEach((item) => {
              if (errors[item.path]) {
                errors[item.path] += "/" + item.message
              } else {
                errors[item.path] = item.message
              }
            })
            commit(CouponMutation.CreationErrors, errors)

            return
          } else if (res?.data?.errors?.[0]?.message) {
            commit(CouponMutation.CreationErrors, { global: res?.data?.errors?.[0]?.message } )

            return
          }

          commit(CouponMutation.CreationSucceed)
          commit(CouponMutation.CloseModal)
          dispatch(CouponAction.GetAll)
          dispatch(CouponAction.GetOne, { id })
        })
        .catch(() => commit(CouponMutation.CreationErrors, { global: 'Internal error' } ))
    },

    [CouponAction.ApplyFilters]: async ({ commit, dispatch }, payload) => {
      commit(CouponMutation.ApplyFilters, payload)
      commit(CouponMutation.Page, 1)
      dispatch(CouponAction.GetAll)
    },
    [CouponAction.ResetCreationErrors]: async ({ commit }) => {
      commit(CouponMutation.CreationErrors, {})
    },
    [CouponAction.Deactivate]: async ({ commit, dispatch }, payload) => {
      commit(CouponMutation.Loading)
      http.post('/graphql', JSON.stringify({
        query: DeactivateCouponMutation,
        variables:  payload,
      }))
        .then((res) => {
          const errors = handleGraphQLErrors(res)
          if (errors !== null) {
            commit(CouponMutation.Errors, errors)

            return
          }

          commit(CouponMutation.CloseDeactivationModal)
          dispatch(CouponAction.GetOne, payload)
        })
        .catch(() => commit(CouponMutation.Errors, { internal: true } ))
    },
  },

  getters: {
    [CouponGetter.List]: (state) => {
      return state.coupons.map((coupon) => {
        return {
          ...coupon,
          effectDetails: formatEffect(coupon),
          createdAt: formatDate(coupon.createdAt),
          expiresAt: formatDate(coupon.condition.expiresAt),
        }
      })
    },
    [CouponGetter.Details]: (state) => {
      if (!state.coupon) return null

      const usages = state.coupon.usages.page.map((usage) => {
        return {
          ...usage,
          usedAt: formatDate(usage.usedAt),
          customer: {
            ...usage.customer,
            fullName: formatFullName(usage.customer),
          },
          initialAmount: formatPrice(usage.initialAmount),
          remainingAmount: formatPrice(usage.remainingAmount),
        }
      })
      const condition = {
        expiresAt: formatDatetime(state.coupon.condition.expiresAt),
        customer: state.coupon.condition.customer ? {
          ...state.coupon.condition.customer,
          fullName: formatFullName(state.coupon.condition.customer),
        } : null,
        numberOfOrders: state.coupon.condition.numberOfOrders,
        newsletterSubscriptionDays: state.coupon.condition.newsletterSubscriptionDays,
        minimalAmount: state.coupon.condition.minimalAmount
          ? formatPrice(state.coupon.condition.minimalAmount)
          : null,
        appOnly: state.coupon.condition.appOnly ? 'oui' : 'non',
        countries: state.coupon.condition.countries,
      }

      return {
        ...state.coupon,
        createdAt: formatDatetime(state.coupon.createdAt),
        condition,
        effectDetails: formatEffect(state.coupon),
        usages,
        totalUsages:   state.coupon.usages.totalNumberOfItems,
      }
    },
    [CouponGetter.RawCoupon]: (state) => {

      const condition = {
        ...state.coupon.condition,
        customerId: state.coupon.condition.customer?.id,
      }

      return {
        ...state.coupon,
        condition,
      }
    },

    [CouponGetter.IsAddModalOpened]: (state) => state.showAddModal,
    [CouponGetter.IsEditModalOpened]: (state) => state.showEditModal,
    [CouponGetter.IsLoading]: (state) => state.status === Status.Loading,
    [CouponGetter.HasError]: (state) => state.status === Status.Failed,
    [CouponGetter.Page]: (state) => state.page,
    [CouponGetter.TotalPages]: (state) => state.totalPages,
    [CouponGetter.CreationErrors]: (state) => state.creationErrors,
    [CouponGetter.Filters]: (state) => state.filters,
    [CouponGetter.UsagePage]: (state) => state.usagePage,
    [CouponGetter.TotalUsagePages]: (state) => state.totalUsagePages,
    [CouponGetter.IsDeactivationModalOpened]: (state) => state.showDeactivationModal,
    [CouponGetter.Errors]: (state) => state.errors,
  },
}
