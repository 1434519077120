<template>
  <v-container class="text-center">
    <v-dialog
        v-model="isLoading"
        persistent
        width="500"
        style="z-index: 9999;"
    >
      <v-card
          color="secondary"
          class="py-4"
      >
        <v-card-text>
          Chargement
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
  computed: {
    isLoading() {
      return this.loading
    },
  },
}
</script>
