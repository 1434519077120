import { formatPercent, formatPrice } from "../utils"

export const formatEffect = (coupon) => {
  if (coupon.effect?.freeShipping) {
    let text = "livraison gratuite"
    if (
      coupon.effect.freeShipping.ceilingAmount?.amount &&
      coupon.effect.freeShipping.ceilingAmount?.currency
    ) {
      text += ' (' + formatPrice({
        amount: coupon.effect.freeShipping.ceilingAmount.amount,
        currency: coupon.effect.freeShipping.ceilingAmount.currency,
      }) + ' max)'
    }

    return text
  }
  if (coupon.effect?.reductionPercent) {
    let text = formatPercent((-1 * coupon.effect.reductionPercent?.percent) / 100)

    switch (coupon.effect.target) {
    case 'PRODUCT':
      text += ' sur les produits'
      break
    case 'SHIPPING':
      text += ' sur la livraison'
      break
    }
    if (
      coupon.effect.reductionPercent.ceilingAmount?.amount &&
      coupon.effect.reductionPercent.ceilingAmount?.currency
    ) {
      text += ' (' + formatPrice({
        amount: coupon.effect.reductionPercent.ceilingAmount.amount,
        currency: coupon.effect.reductionPercent.ceilingAmount.currency,
      }) + ' max)'
    }

    return text
  }
  if (
    coupon.effect?.reductionAmount?.amount &&
    coupon.effect?.reductionAmount?.currency
  ) {
    let text = formatPrice({
      amount: -1 * coupon.effect.reductionAmount.amount,
      currency: coupon.effect.reductionAmount.currency,
    })

    switch (coupon.effect.target) {
    case 'PRODUCT':
      text += ' sur les produits'
      break
    case 'SHIPPING':
      text += ' sur la livraison'
      break
    }

    return text

  }

  return ""
}

export const effects = {
  amount: 'amount',
  percent: 'percent',
  freeShipping: 'freeShipping',
}