import { AuthGetter, AuthMutation } from "./store/types"
import CouponDetails from "./components/Coupon/Details.vue"
import CouponList from "./components/Coupon/List.vue"
import CustomerDetails from "./components/Customer/Details.vue"
import CustomerList from "./components/Customer/List.vue"
import Dashboard from "./components/Dashboard/Index.vue"
import GiftCardDetails from "./components/GiftCard/Details.vue"
import GiftCardList from "./components/GiftCard/List.vue"
import OrderDetails from "./components/Order/Details.vue"
import OrderList from "./components/Order/List.vue"
import store from './store'
import ToolsBoard from "./components/Tools/Board.vue"
import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
    name: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/orders/:id",
    component: OrderDetails,
    name: "orderDetails",
    meta: { requiresAuth: true },
  },
  {
    path: "/orders",
    component: OrderList,
    name: "orderList",
    meta: { requiresAuth: true },
  },
  {
    path: "/coupons/:id",
    component: CouponDetails,
    name: "couponDetails",
    meta: { requiresAuth: true },
  },
  {
    path: "/coupons",
    component: CouponList,
    name: "couponList",
    meta: { requiresAuth: true },
  },
  {
    path: "/gift-cards/:id",
    component: GiftCardDetails,
    name: "giftCardDetails",
    meta: { requiresAuth: true },
  },
  {
    path: "/gift-cards",
    component: GiftCardList,
    name: "giftCardList",
    meta: { requiresAuth: true },
  },
  {
    path: "/customers",
    component: CustomerList,
    name: "customerList",
    meta: { requiresAuth: true },
  },
  {
    path: "/customers/:id",
    component: CustomerDetails,
    name: "customerDetails",
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    component: Dashboard,
    name: "home",
    meta: { requiresAuth: true },
  },
  {
    path: "/tools",
    component: ToolsBoard,
    name: "toolsBoard",
    meta: { requiresAuth: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.query.token) {

    store.commit(AuthMutation.Login, to.query.token)

    delete to.query.token

    return next(to)
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters[AuthGetter.IsAuthenticated]) {
      next()

      return
    }
    window.location.assign(process.env.VUE_APP_BO_AUTH_URL)
  } else {
    next()
  }
})

const token = window.localStorage.getItem('token')
if (null !== token) {
  store.commit(AuthMutation.Login, window.localStorage.getItem('token'))
}

store.subscribe((mutation) => {
  switch (mutation.type) {
  case AuthMutation.Login:
    window.localStorage.setItem('token', store.getters[AuthGetter.Token])
    break

  case AuthMutation.Logout:
    window.localStorage.removeItem('token')
    window.location.replace(process.env.VUE_APP_BO_LOGOUT_REDIR_URL)
    break
  }
})

export default router
