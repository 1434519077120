<template>
  <Page :loading="loading" :error="error" type="stats">
    <template v-slot:title>
      <v-row>
        <v-col>
          Dashboard
        </v-col>
        <v-col>
          <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateFormatted"
                  label="Date"
                  hint="YYYY/MM/DD"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date = parseDate(dateFormatted)"
                  v-on="on"
                  width="200px"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                no-title
                @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </template>
    <template>
      <div>
        <Stats title="Journalier" :stats-period="daily" />
        <Stats title="Mensuel" :stats-period="monthly" class="pt-10" />
      </div>
    </template>
  </Page>
</template>

<script>
import { DashboardAction, DashboardGetter } from "../../store/types"
import { mapGetters } from 'vuex'
import Page from "../Layout/Page"
import Stats from './Stats.vue'

export default {
  components: {
    Stats,
    Page,
  },
  data () {
    const now =  new Date()
    const date = now.getFullYear() + '-' +
        ('' + (now.getMonth() + 1)).padStart(2, '0') + '-' +
        ('' + now.getDate()).padStart(2, '0')

    return {
      date,
      dateFormatted: this.formatDate(date),
      menu1: false,
    }
  },
  mounted() {
    this.$store.dispatch(DashboardAction.GetAll, this.dateFormatted)
  },
  watch: {
    date () {
      this.dateFormatted = this.formatDate(this.date)
      this.$store.dispatch(DashboardAction.GetAll, this.date)
    },
  },

  computed: {
    ...mapGetters({
      daily: DashboardGetter.GetDaily,
      monthly: DashboardGetter.GetMonthly,
      loading: DashboardGetter.IsLoading,
      error: DashboardGetter.HasError,
    }),
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${year}/${month}/${day}`
    },
    parseDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>
