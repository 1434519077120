import '@mdi/font/css/materialdesignicons.css'
import { colors } from "vuetify/lib"
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        background: '#F5F5F5',
        primary: '#000000',
        secondary: '#e9c09f',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',

        status1: '#00897b',
        status2: '#4caf50',
        status3: '#00bcd4',
        status4: '#ffe082',
        status5: '#b71c1c',

        purple1: colors.purple.lighten4,
        purple2: colors.deepPurple.lighten4,
        purple3: colors.indigo.lighten4,
      },
    },
  },
})
