<template>
  <Page :loading="loading" :error="error" type="list">
    <template v-slot:title>Clients</template>
    <template v-slot:no-reload>
      <v-form>
      <v-row>
        <v-col cols="10" md="3">
          <v-text-field v-model="filters.search"
            label="Rechercher"
            clearable
            hint="par nom de famille ou email"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    </template>
    <template v-if="customers.length">
        <v-simple-table>
          <thead>
          <tr>
            <th>Id</th>
            <th>Nom</th>
            <th>Email</th>
            <th>Date de création</th>
            <th class="text-right">-</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="customer in customers"
              :key="customer.id"
              tabIndex="0"
              @keypress.enter="() => onCustomerClick(customer.id)"
          >
            <td>
              <router-link :to="{ name: 'customerDetails', params: { id: customer.id } }">
                #{{ customer.shortId }}
              </router-link>
            </td>
            <td>{{ customer.fullName }}</td>
            <td>{{ customer.emailAddress }}</td>
            <td>{{ customer.createdAt }}</td>
            <td class="text-right">
              <v-icon v-if="customer.deactivated" class="mr-2" color="red darken-3">
                mdi-account-lock-outline
              </v-icon>
              <v-icon v-if="customer.anonymizedAt !== null" class="mr-2" color="red darken-3">
                mdi-incognito
              </v-icon>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
        <div class="text-center mt-8" v-if="nbPages">
          <v-pagination
              v-model="page"
              :length="nbPages"
              :total-visible="8"
              rounded="circle"
          ></v-pagination>
        </div>
      </template>
  </Page>
</template>

<script>
import { CustomerAction, CustomerGetter } from "../../store/types"
import { mapGetters } from 'vuex'
import Page from "../Layout/Page"

let debounceTimerId

export default {
  components: {
    Page,
  },

  data () {
    return {
      filters: this.$store.getters[CustomerGetter.Filters],
    }
  },

  mounted() {
    this.$store.dispatch(CustomerAction.GetAll)
  },

  computed: {
    ...mapGetters({
      customers: CustomerGetter.List,
      loading: CustomerGetter.IsLoading,
      error: CustomerGetter.HasError,
      nbPages: CustomerGetter.TotalPages,
    }),
    page: {
      get() { return this.$store.getters[CustomerGetter.Page] },
      set(value) { this.$store.dispatch(CustomerAction.GetAll, value) },
    },
  },

  methods: {
    onCustomerClick(id) {
      this.$router.push({ name: "customerDetails", params: { id } })
    },
  },

  watch: {
    "filters.search"() {
      clearTimeout(debounceTimerId)
      debounceTimerId = setTimeout(() => {
        this.$store.dispatch(CustomerAction.ApplyFilters, this.filters)
      }, 600)
    },
  },
}
</script>
