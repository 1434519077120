<template>
  <v-chip
      label
      class="text-uppercase"
      :color="getStatusColor()"
  >
    {{ getStatusText() }}
  </v-chip>
</template>

<script>

export default {
  props: {
    status: String,
  },
  methods: {
    getStatusColor() {
      switch (this.status) {
      case 'COMPLETED':
        return 'status1'
      case 'PAID':
        return 'status2'
      case 'CHECKOUT':
        return 'status3'
      case 'ABANDONED':
        return 'status4'
      case 'REFUNDED':
        return 'status5'
      }
    },
    getStatusText() {
      switch (this.status) {
      case 'COMPLETED':
        return 'completed'
      case 'PAID':
        return 'paid'
      case 'CHECKOUT':
        return 'checkout'
      case 'ABANDONED':
        return 'abandoned'
      case 'REFUNDED':
        return 'refunded'
      }
    },
  },
}

</script>
