import { AuthGetter, AuthMutation } from "../types"
import jwtDecode from "jwt-decode"

export default {
  state: {
    token: null,
    user: null,
    role: null,
  },
  mutations: {
    [AuthMutation.Login]: (state, token) => {
      state.token = token
      const data = jwtDecode(token)
      state.user = data.emailAddress
      state.role = data.role
    },
    [AuthMutation.Logout]: (state) => {
      state.token = null
    },
  },
  getters: {
    [AuthGetter.IsAuthenticated]: (state) => state.token !== null,
    [AuthGetter.User]: (state) => state.user,
    [AuthGetter.Token]: (state) => state.token,
  },
}