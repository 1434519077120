<template>
  <v-dialog v-model="isOpened" width="auto" @keydown.esc="close">
    <v-card max-width="700" min-width="500" data-testId="modal-deactivation" v-if="coupon">
      <v-card-title>
        Désactivation de {{ coupon.code }}
      </v-card-title>
        <v-alert
          v-if="errors.internal"
          dense
          prominent
          type="error"
        >Une erreur inattendue s'est produite.</v-alert>
        <v-card-text>
          <v-alert
              border="left"
              type="warning"
              colored-border
              elevation="2"
          >
              Le coupon <b>ne pourra plus être utilisé</b>. Mais il est possible de le réactiver et
            ainsi de lui permettre d'être utilisé à nouveau.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="close()" outlined>
            <v-icon left>mdi-close-circle-outline</v-icon>
            Annuler
          </v-btn>
          <v-spacer />
          <v-btn submit @click="save()" color="red darken-4" outlined>
            <v-icon left>mdi-account-lock-outline</v-icon>
            Désactiver
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { CouponAction, CouponGetter, CouponMutation } from "../../store/types"
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isOpened: CouponGetter.IsDeactivationModalOpened,
      coupon: CouponGetter.Details,
      errors: CouponGetter.Errors,
    }),
  },
  methods: {
    close() {
      this.$store.commit(CouponMutation.CloseDeactivationModal)
    },
    save() {
      this.$store.dispatch(CouponAction.Deactivate, { id: this.coupon.id })
    },
  },
}
</script>
