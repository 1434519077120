<template>
  <v-container fluid>
    <h1><slot name="title"></slot></h1>
    <slot name="no-reload"></slot>
    <LoadingState :loading="loading" />
    <v-alert
        v-if="error"
        dense
        prominent
        type="error"
    >Une erreur inattendue s'est produite.</v-alert>
    <template v-if="loading">
      <v-skeleton-loader
          v-if="'list' === type"
          type="table"
          :loading="loading" />
      <v-skeleton-loader
          v-if="'card' === type"
          type="card"
          :loading="loading" />
      <div v-else>
        <v-row>
          <v-col>
            <v-skeleton-loader type="heading" :loading="loading" class="pb-5"/>
            <v-skeleton-loader type="image" :loading="loading" max-height="100" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-skeleton-loader type="heading" :loading="loading" class="pb-5"/>
            <v-skeleton-loader type="image" :loading="loading" max-height="100" />
          </v-col>
        </v-row>
      </div>
    </template>
    <slot v-else></slot>
    <!-- just to keep the opened form modal states -->
    <slot name="form-modals"></slot>
  </v-container>
</template>

<script>
import LoadingState from './LoadingState'

export default {
  components: {
    LoadingState,
  },
  props: {
    loading: Boolean,
    error: Boolean,
    type: String,
  },
}
</script>
