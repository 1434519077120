export const GetDashboard = `
  query GetDashboard($date: String)  {
    daily: dashboard(period: DAILY, date: $date) {
      dateRange {
        from
        to
      }
      stats {
        type
        value
        evolutions {
          period
          dateRange {
            from
            to
          }
          evolution
        }
      }
    }
    monthly: dashboard(period: MONTHLY, date: $date) {
      dateRange {
        from
        to
      }
      stats {
        type
        value
        evolutions {
          period
          dateRange {
            from
            to
          }
          evolution
        }
      }
    }
  }
`