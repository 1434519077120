import { DashboardAction, DashboardGetter, DashboardMutation } from "../types"
import { formatDatetime, formatNumber, formatPercent, formatPrice } from '../../utils'
import { GetDashboard } from "../../graphql/dashboard"
import http from "../../http"

const Status = {
  Loading: "loading",
  Failed: "failed",
  Succeed: "succeed",
}

function formatPeriodStats(periodStats) {
  return {
    dateRange: {
      from: formatDatetime(periodStats.dateRange?.from),
      to: formatDatetime(periodStats.dateRange?.to),
    },
    stats: periodStats.stats?.map(
      stat => ({
        ...stat,
        value: ['gmv', 'refundedAmount'].includes(stat.type)
          ? formatPrice({ amount: stat.value, currency: 'EUR' })
          : formatNumber(stat.value),
        evolutions: stat.evolutions?.map(
          evolution => ({
            ...evolution,
            dateRange: {
              from: formatDatetime(evolution.dateRange?.from),
              to: formatDatetime(evolution.dateRange?.to),
            },
            value:  evolution.evolution !== null
              ? formatPercent(evolution.evolution / 100, { alwaysShowSign: true })
              : '-',
            progression: !evolution.evolution ? '' : evolution.evolution > 0 ? '+' : '-',
          })
        ),
      }
      )),
  }
}

export default {
  state: {
    daily: [],
    monthly: [],
    status: null,
  },
  mutations: {
    [DashboardMutation.GetAll]: (state, { daily, monthly }) => {
      state.daily = daily
      state.monthly = monthly
      state.status = Status.Succeed
    },
    [DashboardMutation.Failed]: (state) => state.status = Status.Failed,
    [DashboardMutation.Loading]: (state) => state.status = Status.Loading,
  },
  actions: {
    [DashboardAction.GetAll]: async ({ commit }, date) => {
      commit(DashboardMutation.Loading)

      http.post('/graphql', JSON.stringify({
        query: GetDashboard,
        variables: {
          date,
        },
      }))
        .then((res) => {

          commit(DashboardMutation.GetAll, res.data.data)
        })
        .catch(() => commit(DashboardMutation.Failed))
    },
  },
  getters: {
    [DashboardGetter.GetDaily]: (state) => {
      return formatPeriodStats(state.daily)
    },
    [DashboardGetter.GetMonthly]: (state) => {
      return formatPeriodStats(state.monthly)
    },
    [DashboardGetter.IsLoading]: (state) => state.status === Status.Loading,
    [DashboardGetter.HasError]: (state) => state.status === Status.Failed,
  },
}
