export const getDeliveryTypeColor = (status) => {
  switch (status) {
  case 'STANDARD':
    return 'purple1'
  case 'RELAY':
    return 'purple2'
  case 'TRACKED':
    return 'purple3'
  }
}

export const getDeliveryModeColor = (status) => {

  switch (status) {
  case 'GROUPED':
    return 'accent'
  case 'DIRECT':
    return 'info'
  }
}
