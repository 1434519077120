export const RootAction = {
  Init: "init",
}

export const AuthMutation = {
  Login: "auth/login",
  Logout: "auth/logout",
}

export const AuthGetter = {
  IsAuthenticated: "auth/isAuthenticated",
  User: "auth/user",
  Token: "auth/token",
}

export const OrderMutation = {
  GetAllSucceed: "order/getAllSucceed",
  GetOneSucceed: "order/getOneSucceed",
  GetOrderRefundsSucceed: "order/getOrderRefundsSucceed",
  RefundSucceed: "order/refundSucceed",
  Failed: "order/failed",
  Loading: "order/loading",
  Page: "order/page",
  TotalPages: "order/totalPages",
  OrderRefundsPage: "order/orderRefundsPage",
  OrderRefundsTotalPages: "order/orderRefundsTotalPages",
  ApplyFilters: "order/applyFilters",
  RefundErrors: "order/refundErrors",
  Errors: "order/errors",
}

export const OrderAction = {
  GetAll: "order/getAll",
  GetOne: "order/getOne",
  GetOrderRefunds: "order/getOrderRefunds",
  ApplyFilters: "order/applyFilters",
  Refund: "order/refund",
}

export const OrderGetter = {
  List: "order/list",
  Details: "order/details",
  OrderRefunds: "order/orderRefunds",
  IsLoading: "order/isLoading",
  HasError: "order/hasError",
  Page: "order/page",
  TotalPages: "order/totalPages",
  OrderRefundsPage: "order/orderRefundsPage",
  OrderRefundsTotalPages: "order/orderRefundsTotalPages",
  RefundErrors: "order/refundErrors",
  Filters: "order/filters",
}

export const CouponMutation = {
  GetAllSucceed: "coupon/getAllSucceed",
  GetOneSucceed: "coupon/getOneSucceed",
  CreationSucceed: "coupon/creationSucceed",
  Failed: "coupon/failed",
  Loading: "coupon/loading",
  ApplyFilters: "coupon/applyFilters",
  Page: "coupon/page",
  TotalPages: "coupon/totalPages",
  OpenAddModal: "coupon/openAddModal",
  OpenEditModal: "coupon/openEditModal",
  CloseModal: "coupon/closeModal",
  CreationErrors: "coupon/CreationErrors",
  UsagePage: "coupon/usagePage",
  TotalUsagePages: "coupon/totalUsagePages",
  OpenDeactivationModal: "coupon/openDeactivationModal",
  CloseDeactivationModal: "coupon/closeDeactivationModal",
  Errors: 'coupon/errors',
}

export const CouponAction = {
  GetAll: "coupon/getAll",
  GetOne: "coupon/getOne",
  Create: "coupon/create",
  Update: "coupon/update",
  ApplyFilters: "coupon/applyFilters",
  Deactivate: "coupon/deactivate",
  ResetCreationErrors: "coupon/resetCreationErrors",
}

export const CouponGetter = {
  List: "coupon/list",
  Details: "coupon/details",
  RawCoupon: "coupon/rawCoupon",
  IsAddModalOpened: "coupon/isAddModalOpened",
  IsEditModalOpened: "coupon/isEditModalOpened",
  IsLoading: "coupon/isLoading",
  HasError: "coupon/hasError",
  Page: "coupon/page",
  TotalPages: "coupon/totalPages",
  CreationErrors: "coupon/creationErrors",
  Filters: "coupon/filters",
  UsagePage: "coupon/usagePage",
  TotalUsagePages: "coupon/totalUsagePages",
  Errors: 'coupon/errors',
  IsDeactivationModalOpened: "coupon/isDeactivationModalOpened",
}

export const GiftCardMutation = {
  GetAllSucceed: "giftCard/getAllSucceed",
  GetOneSucceed: "giftCard/getOneSucceed",
  Failed: "giftCard/failed",
  Loading: "giftCard/loading",
  ApplyFilters: "giftCard/applyFilters",
  Page: "giftCard/page",
  TotalPages: "giftCard/totalPages",
}

export const GiftCardAction = {
  GetAll: "giftCard/getAll",
  GetOne: "giftCard/getOne",
  ApplyFilters: "giftCard/applyFilters",
}

export const GiftCardGetter = {
  List: "giftCard/list",
  Details: "giftCard/details",
  IsLoading: "giftCard/isLoading",
  HasError: "giftCard/hasError",
  Page: "giftCard/page",
  TotalPages: "giftCard/totalPages",
  Filters: "giftCard/filters",
}

export const CustomerMutation = {
  GetAllSucceed: "customer/getAllSucceed",
  GetOneSucceed: "customer/getOneSucceed",
  Failed: "customer/failed",
  Loading: "customer/loading",
  ApplyFilters: "customer/applyFilters",
  Page: "customer/page",
  TotalPages: "customer/totalPages",
  OpenDeactivationModal: "customer/openDeactivationModal",
  CloseDeactivationModal: "customer/closeDeactivationModal",
  OpenAnonymizationModal: "customer/openAnonymizationModal",
  CloseAnonymizationModal: "customer/closeAnonymizationModal",
  Errors: 'customer/errors',
}

export const CustomerAction = {
  GetAll: "customer/getAll",
  GetOne: "customer/getOne",
  ApplyFilters: "customer/applyFilters",
  Deactivate: "customer/deactivate",
  Anonymize: "customer/anonymize",
}

export const CustomerGetter = {
  List: "customer/list",
  Details: "customer/details",
  IsLoading: "customer/isLoading",
  HasError: "customer/hasError",
  Filters: "customer/filters",
  Page: "customer/page",
  TotalPages: "customer/totalPages",
  Errors: 'customer/errors',
  IsDeactivationModalOpened: "customer/isDeactivationModalOpened",
  IsAnonymizationModalOpened: "customer/IsAnonymizationModalOpened",
}

export const DashboardAction = {
  GetAll: "dashboard/getAll",
}

export const DashboardGetter = {
  GetDaily: "dashboard/getDaily",
  GetMonthly: "dashboard/getMonthly",
  IsLoading: "dashboard/isLoading",
  HasError: "dashboard/hasError",
}

export const DashboardMutation = {
  GetAll: "dashboard/getAll",
  Failed: "dashboard/failed",
  Loading: "dashboard/loading",

}
