<template>
  <Page :loading="loading" :error="error" >
    <template v-if="giftCard" v-slot:title>Détails de la carte cadeau {{ giftCard.code }}</template>
    <template v-if="giftCard">
      <v-row>
        <v-col cols="12" md="4" >
          <v-card height="100%">
            <v-card-title>
              Général
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="font-weight-medium">Code</v-col>
                <v-col class="font-weight-light">{{ giftCard.code }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">Date de création</v-col>
                <v-col class="font-weight-light">{{ giftCard.createdAt }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">Date d'expiration</v-col>
                <v-col class="font-weight-light">{{ giftCard.expiresAt }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">Montant</v-col>
                <v-col class="font-weight-light">
                  {{ giftCard.amount }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col data-testid="info-tab" cols="12" md="10">
          <v-card>
            <v-card-text>
              <v-tabs v-model="tab">
                <v-tab key="usages">Utilisations</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab" class="my-4">
                <v-tab-item key="usages">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Date</th>
                        <th>Commande</th>
                        <th>Client</th>
                        <th class="text-right">Montant initial</th>
                        <th class="text-right">Montant restant</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="(usage, i) in giftCard.usages"
                          :key="i"
                      >
                        <td>{{ usage.usedAt }}</td>
                        <td>
                          <router-link :to="{ name: 'orderDetails', params: { id: usage.order.id } }">
                            #{{ usage.order.reference }}
                          </router-link>
                        </td>
                        <td>
                          <router-link :to="{ name: 'customerDetails', params: { id: usage.customer.id } }">
                            {{ usage.customer.fullName }}
                          </router-link>
                        </td>
                        <td class="text-right">{{ usage.initialAmount }}</td>
                        <td class="text-right">{{ usage.remainingAmount }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </Page>
</template>

<script>
import { formatDatetime, formatDuration, shortUuid } from "../../utils"
import { GiftCardAction, GiftCardGetter } from "../../store/types"
import { mapGetters } from 'vuex'
import Page from "../Layout/Page"

export default {
  components: {
    Page,
  },

  data() {
    return {
      tab: null,
    }
  },

  mounted() {
    this.$store.dispatch(GiftCardAction.GetOne, this.$route.params.id)
  },

  computed: {
    ...mapGetters({
      loading: GiftCardGetter.IsLoading,
      error: GiftCardGetter.HasError,
      giftCard: GiftCardGetter.Details,
    }),
  },
  methods: {
    formatDatetime,
    formatDuration,
    shortUuid,
  },
}
</script>
