<template>
  <Page :loading="loading" :error="error" type="list">
    <template v-slot:title>Cartes Cadeaux</template>
    <template v-slot:no-reload>
      <v-form>
        <v-row>
          <v-col cols="10" md="3">
            <v-text-field v-model="filters.code" label="Rechercher"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-if="giftCards.length">
      <v-simple-table>
        <thead>
        <tr>
          <th>Code</th>
          <th></th>
          <th class="text-right">Date de création</th>
          <th class="text-right">Date d'expiration</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="giftCard in giftCards"
            :key="giftCard.id"
            tabIndex="0"
            @keypress.enter="() => onGiftCardClick(giftCard.id)"
        >
          <td>
            <router-link :to="{ name: 'giftCardDetails', params: { id: giftCard.id } }">
              {{ giftCard.code }}
            </router-link>
          </td>
          <td class="text-right">
            {{ giftCard.amount }}
          </td>
          <td class="text-right">
            {{ giftCard.createdAt }}
          </td>
          <td class="text-right">
            {{ giftCard.expiresAt }}
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <div class="text-center  mt-8" v-if="nbPages">
        <v-pagination
            v-model="page"
            :length="nbPages"
            :total-visible="8"
            rounded="circle"
        ></v-pagination>
      </div>
    </template>
  </Page>
</template>

<script>
import { GiftCardAction, GiftCardGetter } from "../../store/types"
import { mapGetters } from 'vuex'
import Page from "../Layout/Page"

let debounceTimerId

export default {
  components: {
    Page,
  },

  data () {
    return {
      filters: this.$store.getters[GiftCardGetter.Filters],
    }
  },

  mounted() {
    this.$store.dispatch(GiftCardAction.GetAll)
  },

  computed: {
    ...mapGetters({
      giftCards: GiftCardGetter.List,
      loading: GiftCardGetter.IsLoading,
      error: GiftCardGetter.HasError,
      nbPages: GiftCardGetter.TotalPages,
    }),
    page: {
      get() { return this.$store.getters[GiftCardGetter.Page] },
      set(value) { this.$store.dispatch(GiftCardAction.GetAll, value) },
    },
  },

  methods: {
    onGiftCardClick(id) {
      this.$router.push({ name: "giftCardDetails", params: { id } })
    },
  },

  watch: {
    "filters.code"() {
      clearTimeout(debounceTimerId)
      debounceTimerId = setTimeout(() => {
        this.$store.dispatch(GiftCardAction.ApplyFilters, this.filters)
      }, 400)
    },
  },
}
</script>
