import { AuthGetter, AuthMutation } from "./store/types"
import axios from 'axios'
import store from './store'

const http = axios.create({
  baseURL: process.env.VUE_APP_BO_API_BASE_URL,
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
})

http.interceptors.request.use(
  (config) => {
    const token = store.getters[AuthGetter.Token]
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }

    return config
  }
)

http.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      store.commit(AuthMutation.Logout)
    }

    return Promise.reject(error)
  }
)

export default http
