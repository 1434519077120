<template>
  <v-app>
    <NavBar />
    <v-main >
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "./components/Layout/NavBar"
import { RootAction } from "./store/types"

export default {
  components: {
    NavBar,
  },
  beforeCreate() {
    this.$store.dispatch(RootAction.Init)
  },
}

</script>
