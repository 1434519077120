<template>
  <v-dialog v-model="isOpened" width="auto" @keydown.esc="close">
    <v-card max-width="700" min-width="500" data-testId="modal-deactivation" v-if="customer">
      <v-card-title>
        Désactivation de {{ customer.firstName }} / {{ customer.emailAddress }}
      </v-card-title>
        <v-alert
          v-if="errors.internal"
          dense
          prominent
          type="error"
        >Une erreur inattendue s'est produite.</v-alert>
        <v-card-text>
          <v-alert
              border="left"
              type="warning"
              colored-border
              elevation="2"
          >
              L'utilisateur <b>ne pourra plus se connecter</b>. Mais il est possible de le réactiver et
            ainsi de lui permettre de se connecter à nouveau.
          </v-alert>
          <v-alert
            border="left"
            type="warning"
            colored-border
            elevation="2"
          >
            Les <b>données personnelles</b> (nom, prénom, coordonnées, ... ) de l'utilisateur
            <b>ne seront pas anonymisées</b>.
          </v-alert>
          <v-alert
            border="left"
            type="warning"
            colored-border
            elevation="2"
          >
          L'utilisateur <b>ne sera pas supprimé</b> des communications Marketing & promotionnelles.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="close()" outlined>
            <v-icon left>mdi-close-circle-outline</v-icon>
            Annuler
          </v-btn>
          <v-spacer />
          <v-btn submit @click="save()" color="red darken-4" outlined>
            <v-icon left>mdi-account-lock-outline</v-icon>
            Désactiver
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { CustomerAction, CustomerGetter, CustomerMutation } from "../../store/types"
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isOpened: CustomerGetter.IsDeactivationModalOpened,
      customer: CustomerGetter.Details,
      errors: CustomerGetter.Errors,
    }),
  },
  methods: {
    close() {
      this.$store.commit(CustomerMutation.CloseDeactivationModal)
    },
    save() {
      this.$store.dispatch(CustomerAction.Deactivate, this.customer.id)
    },
  },
}
</script>
