export const GetCouponsQuery = `
  query GetCoupons($page: Int!, $limit: Int!, $filters: CouponFiltersInput) {
    coupons(limit: $limit, filters: $filters) {
      totalNumberOfPages
      page (page: $page) {
        id
        code
        isAuto
        createdAt
        deactivated
        effect {
          freeShipping {
            ceilingAmount {
              amount
              currency
            }
          }
          reductionAmount {
            amount
            currency
          }
          reductionPercent {
            percent
            ceilingAmount {
              amount
              currency
            }
          }
          target
          targetStoreId
        }
        condition {
          customer { id }
          expiresAt
          numberOfOrders
          newsletterSubscriptionDays
          minimalAmount { amount currency }
          appOnly
          countries
          deliveryCarrier
          numberOfProducts {
            nb
            storeId
          }
        }
        usages {
          totalNumberOfItems
        }
      }
    }
  }
`

export const GetCouponQuery = `
  query GetCoupon($id: CouponId!, $pageCouponUsages: Int! = 1) {
    coupon (id: $id) {
      id
      code
      isAuto
      offerNameFr: offerName(locale: FR)
      offerNameEn: offerName(locale: EN)
      offerDescriptionFr: offerDescription(locale: FR)
      offerDescriptionEn: offerDescription(locale: EN)
      createdAt
      deactivated
      effect {
        freeShipping {
          ceilingAmount {
            amount
            currency
          }
        }
        reductionAmount {
          amount
          currency
        }
        reductionPercent {
          percent
          ceilingAmount {
            amount
            currency
          }
        }
        target
        targetStoreId
      }
      condition {
        customer {
          id
          firstName
          lastName
        }
        expiresAt
        numberOfOrders
        newsletterSubscriptionDays
        minimalAmount {
            amount
            currency
          }
        appOnly
        countries
        deliveryCarrier
        numberOfProducts {
          nb
          storeId
        }
      }
      usages {
        totalNumberOfPages
        totalNumberOfItems
        page(page: $pageCouponUsages) {
          order {
            id
            reference
            deliveryAddress {
              countryCode
            }
          }
          customer {
            id
            firstName
            lastName
          }
          usedAt
          initialAmount {
            amount
            currency
          }
          remainingAmount {
            amount
            currency
          }
        }
      }
    }
  }
`

export const CreateCouponAmountMutation = `
  mutation CreateCoupon($input: CouponAmountInput!) {
    createCouponAmount (input: $input) {
      id
      code
      isAuto
      offerNameFr: offerName(locale: FR)
      offerNameEn: offerName(locale: EN)
      offerDescriptionFr: offerDescription(locale: FR)
      offerDescriptionEn: offerDescription(locale: EN)
      createdAt
      effect {
        freeShipping {
          ceilingAmount {
            amount
            currency
          }
        }
        reductionAmount {
          amount
          currency
        }
        reductionPercent {
          percent
          ceilingAmount {
            amount
            currency
          }
        }
        target
        targetStoreId
      }
    }
  }
`

export const CreateCouponFreeShippingMutation = `
  mutation CreateCoupon($input: CouponFreeShippingInput!) {
    createCouponFreeShipping (input: $input) {
      id
      code
      isAuto
      offerNameFr: offerName(locale: FR)
      offerNameEn: offerName(locale: EN)
      offerDescriptionFr: offerDescription(locale: FR)
      offerDescriptionEn: offerDescription(locale: EN)
      createdAt
      effect {
        freeShipping {
          ceilingAmount {
            amount
            currency
          }
        }
        reductionAmount {
          amount
          currency
        }
        reductionPercent {
          percent
          ceilingAmount {
            amount
            currency
          }
        }
        target
        targetStoreId
      }
    }
  }
`

export const CreateCouponPercentMutation = `
  mutation createCoupon($input: CouponPercentInput!) {
    createCouponPercent (input: $input) {
      id
      code
      isAuto
      offerNameFr: offerName(locale: FR)
      offerNameEn: offerName(locale: EN)
      offerDescriptionFr: offerDescription(locale: FR)
      offerDescriptionEn: offerDescription(locale: EN)
      createdAt
      effect {
        freeShipping {
          ceilingAmount {
            amount
            currency
          }
        }
        reductionAmount {
          amount
          currency
        }
        reductionPercent {
          percent
          ceilingAmount {
            amount
            currency
          }
        }
        target
        targetStoreId
      }
    }
  }
`

export const UpdateCouponAmountMutation = `
  mutation UpdateCoupon($input: CouponAmountInput!) {
    updateCouponAmount (input: $input) {
      id
      code
      isAuto
      offerNameFr: offerName(locale: FR)
      offerNameEn: offerName(locale: EN)
      offerDescriptionFr: offerDescription(locale: FR)
      offerDescriptionEn: offerDescription(locale: EN)
      createdAt
      effect {
        freeShipping {
          ceilingAmount {
            amount
            currency
          }
        }
        reductionAmount {
          amount
          currency
        }
        reductionPercent {
          percent
          ceilingAmount {
            amount
            currency
          }
        }
        target
        targetStoreId
      }
    }
  }
`

export const UpdateCouponFreeShippingMutation = `
  mutation UpdateCoupon($input: CouponFreeShippingInput!) {
    updateCouponFreeShipping (input: $input) {
      id
      code
      isAuto
      offerNameFr: offerName(locale: FR)
      offerNameEn: offerName(locale: EN)
      offerDescriptionFr: offerDescription(locale: FR)
      offerDescriptionEn: offerDescription(locale: EN)
      createdAt
      effect {
        freeShipping {
          ceilingAmount {
            amount
            currency
          }
        }
        reductionAmount {
          amount
          currency
        }
        reductionPercent {
          percent
          ceilingAmount {
            amount
            currency
          }
        }
        target
        targetStoreId
      }
    }
  }
`

export const UpdateCouponPercentMutation = `
  mutation updateCoupon($input: CouponPercentInput!) {
    updateCouponPercent (input: $input) {
      id
      code
      isAuto
      offerNameFr: offerName(locale: FR)
      offerNameEn: offerName(locale: EN)
      offerDescriptionFr: offerDescription(locale: FR)
      offerDescriptionEn: offerDescription(locale: EN)
      createdAt
      effect {
        freeShipping {
          ceilingAmount {
            amount
            currency
          }
        }
        reductionAmount {
          amount
          currency
        }
        reductionPercent {
          percent
          ceilingAmount {
            amount
            currency
          }
        }
        target
        targetStoreId
      }
    }
  }
`

export const DeactivateCouponMutation = `
  mutation DeactivateCoupon(
    $id: CouponId!
  ){
    deactivateACoupon(id: $id){
        code
    }
  }
`
