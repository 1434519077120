import {
  deliveryCarrierIconSrc, formatDate,
  formatDatetime,
  formatFullName,
  formatInlineAddress,
  formatPrice,
  formatSizes,
  paymentServiceProviderIconSrc,
} from '../../utils'
import {
  GetOrderQuery,
  GetOrdersQuery,
  RefundOrderMutation,
} from "../../graphql/order"
import { OrderAction, OrderGetter, OrderMutation } from "../types"
import { GetOrderRefunds } from "../../graphql/orderRefunds"
import http from "../../http"

const Status = {
  Loading: "loading",
  Failed: "failed",
  Succeed: "succeed",
}

const resultsPerPage = 30

export default {
  state: {
    orders: [],
    order: null,
    status: null,
    errors: {},
    refundErrors: {},
    page: 1,
    totalPages: null,
    filters: {},
    orderRefunds: {
      orderRefunds: [],
      page: 1,
      totalPages: null,
    },
  },
  mutations: {
    [OrderMutation.GetAllSucceed]: (state, payload) => {
      state.orders = payload
      state.status = Status.Succeed
    },
    [OrderMutation.GetOneSucceed]: (state, payload) => {
      state.order =   payload
      state.status = Status.Succeed
    },
    [OrderMutation.GetOrderRefundsSucceed]: (state, payload) => {
      state.orderRefunds.orderRefunds = payload
      state.status = Status.Succeed
    },
    [OrderMutation.RefundSucceed]: (state) => state.status = Status.Succeed,
    [OrderMutation.RefundErrors]: (state, error) => {
      state.refundErrors = error
      state.status = Status.Default
    },
    [OrderMutation.Errors]: (state, error) => {
      state.errors = error
      state.status = Status.Default
    },
    [OrderMutation.Failed]: (state) => state.status = Status.Failed,
    [OrderMutation.Loading]: (state) => state.status = Status.Loading,

    [OrderMutation.Page]: (state, payload) => state.page = payload,
    [OrderMutation.TotalPages]: (state, payload) => state.totalPages = payload,

    [OrderMutation.OrderRefundsPage]: (state, payload) => state.orderRefunds.page = payload,
    [OrderMutation.OrderRefundsTotalPages]: (state, payload) => state.orderRefunds.totalPages = payload,

    [OrderMutation.ApplyFilters]: (state, payload) => state.filters = payload,
  },
  actions: {
    [OrderAction.GetAll]: async ({ state, commit }, page) => {
      commit(OrderMutation.Loading)

      page = page || state.page || 1
      http.post('/graphql', JSON.stringify({
        query: GetOrdersQuery,
        variables: {
          page: page,
          limit: resultsPerPage,
          filters: state.filters,
        },
      }))
        .then((res) => {
          commit(OrderMutation.Page, page)
          commit(OrderMutation.GetAllSucceed, res.data.data.orders.page),
          commit(OrderMutation.TotalPages, res.data.data.orders.totalNumberOfPages)
        })
        .catch(() => commit(OrderMutation.Failed))
    },
    [OrderAction.GetOne]: async ({ commit }, payload) => {
      commit(OrderMutation.Loading)

      http.post('/graphql', JSON.stringify({
        query: GetOrderQuery,
        variables: { id: payload },
      }))
        .then((res) => commit(OrderMutation.GetOneSucceed, res.data.data.order))
        .catch(() => commit(OrderMutation.Failed))
    },

    [OrderAction.GetOrderRefunds]: async ({ state, commit }, payload, page) => {
      commit(OrderMutation.Loading)
      page = page || state.orderRefunds.page || 1
      http.post('/graphql', JSON.stringify({
        query: GetOrderRefunds,
        variables: {
          orderId: payload,
          page: page,
        },
      }))
        .then((res) => {
          commit(OrderMutation.OrderRefundsPage, page)
          commit(OrderMutation.GetOrderRefundsSucceed, res.data.data.orderRefunds.page)
          commit(OrderMutation.OrderRefundsTotalPages, res.data.data.orderRefunds.totalNumberOfPages)
        })
        .catch(() => commit(OrderMutation.Failed))
    },

    [OrderAction.ApplyFilters]: async ({ commit, dispatch }, payload) => {
      commit(OrderMutation.ApplyFilters, payload)
      commit(OrderMutation.Page, 1)
      dispatch(OrderAction.GetAll)
    },

    [OrderAction.Refund]: async (
      { state, commit, dispatch },
      { shippingRefundAmount, qualityControlRefundAmount, productRefundAmounts, note, reason } ) => {

      commit(OrderMutation.Loading)

      http.post('/graphql',  JSON.stringify({
        query: RefundOrderMutation,
        variables: {
          orderId: state.order.id,
          productRefunds: productRefundAmounts,
          shippingRefund: shippingRefundAmount,
          qualityControlRefund: qualityControlRefundAmount,
          reason: reason,
          note: note,
        },
      }))
        .then((res) => {
          if (res?.data?.errors) {
            const errors = {}

            if (res?.data?.errors?.[0]?.state?.input) {
              res.data.errors[0].state.input.forEach((item) => {
                if (errors[item.path]) {
                  errors[item.path] += "/" + item.message
                } else {
                  errors[item.path] = item.message
                }
              })
            } else {
              errors['internal'] = true
            }
            commit(OrderMutation.RefundErrors, errors)

            return
          }

          commit(OrderMutation.RefundSucceed)
          dispatch(OrderAction.GetOne, state.order.id)
          dispatch(OrderAction.GetOrderRefunds, state.order.id)
        })
        .catch(() => commit(OrderMutation.RefundErrors, { internal: true } ))
    },
  },
  getters: {
    [OrderGetter.List]: (state) => {
      return state.orders.map((order) => {
        return {
          ...order,
          createdAt: formatDatetime(order.createdAt, false),
          customerName: formatFullName(order.customer),
          deliveryAddress: formatInlineAddress(order.deliveryAddress),
          countryCode: order.deliveryAddress.countryCode,
          totalAmount: formatPrice(order.totalAmount),
          paidAmount: formatPrice(order.paidAmount),
          refundedAmount: formatPrice(
            order.refundedAmount,
            { additiveInverse: true }
          ),
          shippingMethod: {
            ...order.shippingMethod,
            deliveryCarrierIconSrc: deliveryCarrierIconSrc(order.shippingMethod.deliveryCarrier),
          },
          paymentServiceProviderIconSrc: paymentServiceProviderIconSrc(order.paymentServiceProvider),
        }
      })
    },
    [OrderGetter.Details]: (state) => {
      if (!state.order) return null

      const eligibility = {}

      state.order.parcels?.forEach((parcel) => parcel.items.reduce((eligibility, item) => {
        eligibility[item.orderItem.id] = item.returnEligibility

        return eligibility
      }, eligibility))

      const orderItems = state.order.orderItems.map((orderItem) => ({
        ...orderItem,
        amount: formatPrice(orderItem.amount),
        paidAmount: formatPrice(orderItem.paidAmount),
        reductionAmount: formatPrice(orderItem.reductionAmount),
        refundableAmountValue: orderItem.refundableAmount.amount / 100,
        refundedAmount: formatPrice(
          orderItem.refundedAmount,
          { zeroAsNull: true, additiveInverse: true }
        ),
        product: {
          ...orderItem.product,
          size: formatSizes(orderItem.product.size),
        },
        returnEligibility: eligibility[orderItem.id] ? {
          ...eligibility[orderItem.id],
          dateLimit: formatDate(eligibility[orderItem.id].dateLimit),
        } : { eligible: false },
      }))

      const orderItemsByStores = {}
      for (const orderItem of orderItems) {

        if (!orderItemsByStores[orderItem.product.store.id]) {
          orderItemsByStores[orderItem.product.store.id] = {
            store: orderItem.product.store,
            orderItems: [],
          }
        }
        orderItemsByStores[orderItem.product.store.id].orderItems.push(orderItem)
      }

      return {
        ...state.order,
        orderItemsByStores,
        createdAt: formatDatetime(state.order.createdAt),
        paidAt: formatDatetime(state.order.paidAt),
        updatedAt: formatDatetime(state.order.updatedAt),
        customerName: formatFullName(state.order.customer),
        isDirect: state.order.shippingMethod.deliveryMethod === 'DIRECT',
        paymentServiceProviderIconSrc: paymentServiceProviderIconSrc(state.order.paymentServiceProvider),
        deliveryAddress: {
          ...state.order.deliveryAddress,
          phoneNumber: state.order.deliveryAddress.phoneNumber,
        },
        shippingMethod: {
          ...state.order.shippingMethod,
          deliveryCarrierIconSrc: deliveryCarrierIconSrc(state.order.shippingMethod.deliveryCarrier),
        },
        priceDetails: {
          shippingReduction: formatPrice(
            state.order.reductionAmountDetail.shippingAmount,
            {
              additiveInverse: true,
              zeroAsNull: true,
            }
          ),
          qualityControlReduction: formatPrice(
            state.order.reductionAmountDetail.qualityControlAmount,
            {
              additiveInverse: true,
              zeroAsNull: true,
            }
          ),
          reductions: formatPrice(
            state.order.reductionAmount,
            {
              additiveInverse: true,
              zeroAsNull: true,
            }
          ),
          products: formatPrice(state.order.totalAmountDetail.productAmount),
          shipping: formatPrice(state.order.totalAmountDetail.shippingAmount),
          qualityControl: formatPrice(
            state.order.totalAmountDetail.qualityControlAmount,
            {
              zeroAsNull: true,
            }
          ),
          discounts: state.order.discounts.map((discount) => ({
            id: discount.id,
            code: discount.code,
            type: discount.type,
            amount: formatPrice(
              discount.amount,
              {
                additiveInverse: true,
                zeroAsNull: true,
              }
            ),
          })),
          total: formatPrice(state.order.totalAmount),
        },
        paidAmount: formatPrice(state.order.paidAmount),
        shippingPaidAmount: formatPrice(state.order.paidAmountDetail.shippingAmount),
        shippingRefundableAmountValue: state.order.refundableAmountDetail.shippingAmount.amount / 100,
        qualityControlPaidAmount: formatPrice(state.order.paidAmountDetail.qualityControlAmount),
        qualityControlRefundableAmountValue: state.order.refundableAmountDetail.qualityControlAmount.amount / 100,
        refundedAmount: formatPrice(
          state.order.refundedAmount,
          {
            additiveInverse: true,
            zeroAsNull: true,
          }
        ),
        shippingRefundedAmount: formatPrice(
          state.order.refundedAmountDetail.shippingAmount,
          {
            additiveInverse: true,
            zeroAsNull: true,
          }
        ),
        qualityControlRefundedAmount: formatPrice(
          state.order.refundedAmountDetail.qualityControlAmount,
          {
            additiveInverse: true,
            zeroAsNull: true,
          }
        ),
      }
    },

    [OrderGetter.IsLoading]: (state) => state.status === Status.Loading,
    [OrderGetter.HasError]: (state) => state.status === Status.Failed,

    [OrderGetter.Page]: (state) => state.page,
    [OrderGetter.TotalPages]: (state) => state.totalPages,
    [OrderGetter.RefundErrors]: (state) => state.refundErrors,

    [OrderGetter.OrderRefunds]: (state) => {
      return state.orderRefunds.orderRefunds.map((orderRefund) => ({
        ...orderRefund,
        createdAt: formatDatetime(orderRefund.createdAt),
        shippingRefund: formatPrice(orderRefund.shippingRefund),
        qualityControlRefund: formatPrice(orderRefund.qualityControlRefund),
        productRefunds: formatPrice(orderRefund.productRefunds),
        refundedAmount: formatPrice(orderRefund.refundedAmount),
        productRefundInfos: orderRefund.orderItemRefunds.map((orderItemRefund) => ({
          product: orderItemRefund.orderItem.product.name,
          amount: formatPrice(orderItemRefund.refundedAmount),
        })),
      }))
    },
    [OrderGetter.Filters]: (state) => state.filters,
    [OrderGetter.OrderRefundsPage]: (state) => state.orderRefunds.orderRefunds.page,
    [OrderGetter.OrderRefundsTotalPages]: (state) => state.orderRefunds.orderRefunds.totalPages,
  },
}
