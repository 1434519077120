<template>
  <v-dialog
      :value="value"
      persistent
      max-width="290"
  >
    <v-card>
      <v-card-title class="text-h5">
        <slot name="title" />
      </v-card-title>
      <v-card-text>
        <slot name="text" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            cancel
            @click="$emit('cancel')"
        >
          Annuler
        </v-btn>
        <v-btn
            color="green"
            ok
            @click="$emit('confirm')"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      required: false,
    },
  },
}
</script>