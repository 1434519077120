import { formatDate, formatDatetime, formatFullName, formatPrice } from "../../utils"
import { GetGiftCardQuery, GetGiftCardsQuery } from "../../graphql/giftCard"
import {
  GiftCardAction,
  GiftCardGetter,
  GiftCardMutation,
} from "../types"
import http from "../../http"

const Status = {
  Default: "default",
  Loading: "loading",
  Failed: "failed",
  Succeed: "succeed",
}

const resultsPerPage = 15

export default {
  state: {
    giftCards: [],
    giftCard: null,
    status: Status.Default,
    page: 1,
    totalPages: null,
    filters: {},
  },

  mutations: {
    [GiftCardMutation.GetAllSucceed]: (state, payload) => {
      state.giftCards = payload
      state.status = Status.Succeed
    },
    [GiftCardMutation.GetOneSucceed]: (state, payload) => {
      state.giftCard = payload
      state.status = Status.Succeed
    },
    [GiftCardMutation.Failed]: (state) => state.status = Status.Failed,
    [GiftCardMutation.Loading]: (state) => state.status = Status.Loading,
    [GiftCardMutation.Page]: (state, payload) => state.page = payload,
    [GiftCardMutation.TotalPages]: (state, payload) => state.totalPages = payload,
    [GiftCardMutation.ApplyFilters]: (state, payload) => state.filters = payload,
  },

  actions: {
    [GiftCardAction.GetAll]: async ({ state, commit }, page) => {
      commit(GiftCardMutation.Loading)

      page = page || state.page || 1
      http.post('/graphql', JSON.stringify({
        query: GetGiftCardsQuery,
        page: page,
        variables: {
          page: page,
          limit: resultsPerPage,
          filters: state.filters,
        },
      }))
        .then((res) => {
          commit(GiftCardMutation.Page, page)
          commit(GiftCardMutation.GetAllSucceed, res.data.data.giftCards.page)
          commit(GiftCardMutation.TotalPages, res.data.data.giftCards.totalNumberOfPages)
        })
        .catch(() => commit(GiftCardMutation.Failed))
    },
    [GiftCardAction.GetOne]: async ({ commit }, payload) => {
      commit(GiftCardMutation.Loading)

      http.post('/graphql', JSON.stringify({
        query: GetGiftCardQuery,
        variables: { id: payload },
      }))
        .then((res) => commit(GiftCardMutation.GetOneSucceed, res.data.data.giftCard))
        .catch(() => {

          commit(GiftCardMutation.Failed)
        })
    },

    [GiftCardAction.ApplyFilters]: async ({ commit, dispatch }, payload) => {
      commit(GiftCardMutation.ApplyFilters, payload)
      commit(GiftCardMutation.Page, 1)
      dispatch(GiftCardAction.GetAll)
    },
  },

  getters: {
    [GiftCardGetter.List]: (state) => {
      return state.giftCards.map((giftCard) => {
        return {
          ...giftCard,
          createdAt: formatDatetime(giftCard.createdAt),
          expiresAt: formatDate(giftCard.expiresAt),
          amount: formatPrice(giftCard.amount),
        }
      })
    },
    [GiftCardGetter.Details]: (state) => {
      if (!state.giftCard) return null

      const usages = state.giftCard.usages.map((usage) => {
        return {
          ...usage,
          usedAt: formatDate(usage.usedAt),
          customer: {
            ...usage.customer,
            fullName: formatFullName(usage.customer),
          },
          initialAmount: formatPrice(usage.initialAmount),
          remainingAmount: formatPrice(usage.remainingAmount),
        }
      })

      return {
        ...state.giftCard,
        createdAt: formatDatetime(state.giftCard.createdAt),
        expiresAt: formatDate(state.giftCard.expiresAt),
        amount: formatPrice(state.giftCard.amount),
        usages,
      }
    },
    [GiftCardGetter.IsLoading]: (state) => state.status === Status.Loading,
    [GiftCardGetter.HasError]: (state) => state.status === Status.Failed,
    [GiftCardGetter.Page]: (state) => state.page,
    [GiftCardGetter.TotalPages]: (state) => state.totalPages,
    [GiftCardGetter.Filters]: (state) => state.filters,
  },
}
